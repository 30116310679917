<template>
  <div
    :class="classes"
    :style="styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ this.form.id ? $t('generic-str.update') : $t('generic-str.status.lbl-new') }} {{$t('generic-str.department')}}</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="request" class="form-horizontal">
          <div class="modal-body">
            <div class="form-body">
              <div class="form-group row">
                <label class="control-label text-right col-md-4 text-left-m">
                  {{$tc('generic-str.name', 1)}}
                </label>
                <div class="col-md-6">
                  <input
                    v-model="form.name"
                    type="text"
                    class="form-control"
                    required
                  />
                  <!-- <input type="text" :value="data.id"> -->
                  <!-- remove:
                  <span>{{ remove }}</span>
                  <p>{{ data }}</p> -->
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-right col-md-4 text-left-m">
                  {{$t('generic-str.status.lbl-active')}}
                </label>
                <div class="col-md-6">
                  <!-- <input
                    v-model="form.active"
                    type="checkbox"
                    :checked="form.active === true || form.active === 'true'"
                  /> -->
                  <div class="control-group opt-2">
                    <label class="control control-checkbox" for="department-modal-active">
                      <input id="department-modal-active" v-model="form.active" type="checkbox" />
                      <div class="control_indicator"></div>
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-right col-md-4 text-left-m">
                  {{$t('departments.data-infos.lbl-description')}}
                </label>
                <div class="col-md-6">
                  <textarea
                    v-model="form.description"
                    type="text"
                    class="form-control"
                    row="12"
                    required
                  ></textarea>
                  <!-- <input type="text" :value="data.id"> -->
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-yup-purple-outline" @click="hide">{{$t('generic-str.cancel')}}</button>
            <button
              type="button"
              class="btn btn-yup-purple"
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isRequesting }"
              :disabled="isRequesting"
              v-if="remove">{{$t('generic-str.remove')}}</button>
            <button
              type="submit"
              class="btn btn-yup-purple"
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isRequesting }"
              :disabled="isRequesting"
              v-else>{{$t('generic-str.save')}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import DepartmentService from '@/services/department.service';
import Modal from '@/mixins/Modal';

export default {
  mixins: [Modal],
  props: ['id', 'name', 'active', 'remove'],
  data() {
    return {
      form: {
        name: '',
        active: true,
        description: '',
      },
      isRequesting: false,
    };
  },
  methods: {
    request() {
      if (this.form.id) {
        this.update();
      } else {
        this.create();
      }
    },
    create() {
      this.isRequesting = true;

      DepartmentService.createDepartment(this.form).then(
        () => {
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: this.$t('departments.toast.registered'),
            type: 'success',
          });
          this.$emit('created');
          this.isRequesting = false;
          this.hide();
        },
        (error) => {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
            content: error.message,
            type: 'danger',
          });
          this.isRequesting = false;
        },
      );
    },
    update() {
      this.isRequesting = true;
      DepartmentService.updateDepartment(this.data.id, this.form).then(
        () => {
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: this.$t('departments.toast.updated'),
            type: 'success',
          });
          this.$emit('updated');
          this.isRequesting = false;
          this.hide();
        },
        (error) => {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
            content: error.message,
            type: 'danger',
          });
          this.isRequesting = false;
        },
      );
    },
  },
};
</script>
<style scoped>
.department-modal-change + label {
  margin-top: 5px;
}
</style>
