<template>
  <div class="custom-control custom-checkbox checkbox-primary form-check">
    <input
      :id="id"
      v-model="content"
      :checked="value"
      type="checkbox"
      class="custom-control-input"
      @change="handleInput"
    />
    <label class="custom-control-label" :for="id"><slot></slot></label>
  </div>
</template>

<script>
export default {
  name: 'FormCheckbox',
  props: ['id', 'value', 'placeholder', 'readonly'],
  data() {
    return {
      content: this.value,
    };
  },
  watch: {
    value: {
      handler() {
        const checkBox = document.getElementById(this.id);
        checkBox.checked = this.value;
      },
    },
  },
  methods: {
    handleInput(event) {
      this.$emit('input', this.content);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--clr-yup-purple) !important;
  background-color: var(--clr-yup-purple) !important;
}
.custom-control-label {
  line-height: 24px;
  &::before {
    border-radius: 0;
    border: var(--clr-yup-purple) solid 2px;
  }
  &::before,
  &::after {
    height: 18px;
    width: 18px;
  }
}
</style>
