<template>
  <div class="modal_outer">
    <div
      class="modal fade show"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      :style="styles"
      @click="onClickOut"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content" ref="content">
          <div class="modal-header">
            <h5 v-if="title" class="modal-title">{{ title }}</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" class="zmdi zmdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <slot></slot>
          </div>
          <footer class="modal-footer">
            <Button variant="outlined" @click="hide">Cancelar</Button>
            <Button type="submit" @click="handleClick">Confirmar</Button>
          </footer>
        </div>
      </div>
    </div>
    <div v-if="isVisible" class="modal-backdrop"></div>
  </div>
</template>

<script>
import Button from '@/components/rebranding/button/Button.vue';

export default {
  name: 'Modal',
  props: ['id', 'title'],
  components: {
    Button,
  },
  data() {
    return {
      modalId: this.id,
      isVisible: false,
      backdrop: null,
    };
  },
  computed: {
    styles() {
      return {
        display: this.isVisible ? 'block' : 'none',
      };
    },
  },
  mounted() {
    this.$root.$on('show.modal', this.showHandler);
  },
  methods: {
    showHandler(id) {
      if (id === this.modalId) {
        this.show();
      }
    },
    show() {
      this.isVisible = true;
    },
    hide() {
      this.isVisible = false;
    },
    onClickOut(event) {
      if (event.target.contains(this.$refs.content)) {
        this.hide();
      }
    },
    handleClick() {
      this.$emit('confirm');
      this.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-content {
  padding: 0px !important;
}
.modal-header {
  padding: 24px 26px 0 !important;
}
.modal-body {
  padding: 26px 74.5px;
}
.modal-footer {
  padding: 26px 66px;
}
</style>
