import axios from '@/plugins/axios';

const PREFIX = 'departments';

class DepartmentService {
  getInfo() {
    this.a = 'a';
    return axios().get(`${PREFIX}/info`)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  getDepartments(data) {
    this.data = data;
    return axios().get(PREFIX, { params: this.data }).then((response) => response.data);
  }

  createDepartment(data) {
    this.data = data;
    return axios().post(`${PREFIX}`, this.data).then((response) => response.data);
  }

  updateDepartment(id, data) {
    this.id = id;
    console.log('updateDepartment', `${PREFIX}/${id}`);

    return axios().put(`${PREFIX}/${id}`, data)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  removeDepartment(id) {
    this.id = id;
    return axios().delete(`${PREFIX}/${id}`)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }
}

export default new DepartmentService();
