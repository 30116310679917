<template>
  <CardComponent
    ref="card"
    :actions="true"
    :loading="loading"
    :searchable="true"
    :usersDep="true"
    block
    variant="tertiary"
    @messageChanged="search"
    @reload="fetch"
  >
    <template #header>
      {{ $tc('app.department', 2) }}
      <ul class="actions top-right">
        <li>
          <a role="button" @click="fetch(1)">
            <span class="material-symbols-outlined"> refresh </span>
          </a>
        </li>
        <li>
          <a role="button" v-modal="{ target: 'department-modal' }">
            <span class="material-symbols-outlined"> add </span>
          </a>
        </li>
        <li></li>
      </ul>
    </template>
    <TableComponent :busy="loading" :fields="fields" :items="departments">
      <template #cell(active)="slotProps">
        <span v-if="slotProps.data" class="material-symbols-outlined"> done </span>
      </template>
      <template #cell(actions)="slotProps">
        <dropdown>
          <template v-slot:text>
            <span class="material-symbols-outlined title"> more_vert </span>
          </template>
          <template #items>
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              @click="showModalInfo(slotProps.item)"
            >
              <!-- v-modal="{ target: 'department-modal', data: item } -->
              {{ $t('generic-str.edit') }}
            </a>
            <button class="dropdown-item" @click="destroy(slotProps.item.id)">
              {{ $t('generic-str.remove') }}
            </button>
          </template>
        </dropdown>
      </template>
    </TableComponent>
    <department-modal
      id="department-modal"
      ref="department-modal"
      @created="fetch"
      @updated="fetch"
    ></department-modal>
    <div class="card-footer">
      <pagination :lastPage="pages" @change="changePage"/>
    </div>
    </CardComponent>
</template>

<script>
// @ is an alias to /src
import Swal from 'sweetalert2';
import Dropdown from '@/components/Dropdown.vue';
import DepartmentModal from '@/components/rebranding/departments/DepartmentModal.vue';
import DepartmentService from '@/services/department.service';
import Pagination from '@/components/rebranding/Pagination.vue';
import Modal from '@/mixins/Modal';
import CardComponent from '@/components/CardComponent.vue';
import TableComponent from '@/components/TableComponent.vue';

export default {
  name: 'DepartmentsCard',
  mixins: [Modal],
  components: {
    TableComponent,
    CardComponent,
    Dropdown,
    DepartmentModal,
    Pagination,
  },
  data() {
    return {
      loading: true,
      fields: [
        'name',
        'description',
        {
          key: 'active',
          label: 'status',
        },
        'actions',
      ],
      q: '',
      page: 1,
      pages: 1,
      departments: [],
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    showModalInfo(item) {
      // console.info(item);
      // this.$root.$emit('UsersModal', item);
      this.$refs['department-modal'].showHandler('department-modal', item);
    },
    fetch(page = this.page) {
      this.page = page;
      this.loading = true;
      this.$store
        .dispatch('fetchDepartments', { page, q: this.q })
        .then((response) => {
          this.departments = response.data;
          this.pages = response.last_page;
          this.loading = false;
        });
    },
    destroy(id) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text: this.$t('departments.swal.remove-msg'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
        cancelButtonText: this.$t('app.cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          DepartmentService.removeDepartment(id).then(
            () => {
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('departments.toast.deleted'),
                type: 'success',
              });
              this.loading = false;
              this.fetch();
            },
            (error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
                  error.code
                }`,
                content: error.message,
                type: 'danger',
              });
              this.loading = false;
            },
          );
        }
      });
      /* Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text: this.$t('user.destroy'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          this.$store.dispatch('destroyUser', id).then((response) => {
            this.fetch();
          });
        }
      }); */
    },
    changePage(page) {
      /* this.page = page;
      this.$emit('change-page', this.page); */
      this.fetch(page);
    },
    search(q) {
      this.q = q;
      this.fetch(1);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .pagination_row {
  display: none !important;
}

::v-deep {
  .table-responsive {
    overflow: visible;
  }
}
</style>
