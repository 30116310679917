<template>
  <div class="content">
    <PageHeader :title="$tc('app.user', 2)" />
    <div class="page-content container-fluid pb-0">
      <div class="row">
        <div
          class="users-column"
          :class="{
            'col-lg-7': $store.state.plans.omniPlan,
            'col-lg-12': !$store.state.plans.omniPlan,
          }"
        >
          <users-card ref="usersCard" />
        </div>
        <div
          v-if="$store.state.plans.omniPlan"
          class="users-column"
          :class="{
            'col-lg': $store.state.plans.omniPlan,
            'col-lg-12': !$store.state.plans.omniPlan,
          }"
        >
          <departments-card :data="users" @departments="updateUserCards()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/rebranding/PageHeader.vue';
import UsersCard from '@/components/cards/UsersCard.vue';
import DepartmentsCard from '@/components/cards/DepartmentsCard.vue';

export default {
  name: 'Users',
  components: {
    PageHeader,
    UsersCard,
    DepartmentsCard,
  },
  data() {
    return {
      users: [],
      fetched: false,
      form: {
        q: '',
        page: 1,
      },
      pages: 1,
    };
  },
  computed: {
    agentStatus() {
      const agents = {};

      if (this.$live.managedAgents?.length) {
        this.$live.managedAgents.forEach((agent) => {
          if (agent.email) agents[agent.email] = agent.status;
        });
      }

      return agents;
    },
  },
  methods: {
    updateUserCards() {
      this.$refs?.usersCard?.fetch(1);
    },
  },
};
</script>
<style lang="scss" scoped>
.content::v-deep {
  .users-column > .card:not(.card-expand) {
    max-height: 455px;

    @media (min-width: 992px) {
      min-height: 455px;
      max-height: 100vh;
      height: calc(108vh - 240px);
      margin-bottom: 0;
    }
  }

  .users-column > .card .card-body {
    overflow: auto;
  }

  .users-column > .card .card-footer .paging_simple_numbers {
    margin: 0 !important;
  }
}
</style>
